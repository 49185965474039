<template>
  <div class="about__container">
    <div class="about__f-bg__container">
      <div class="bg__container" ref="bg"></div>
      <div class="bg__text-logo scrollObj"></div>
      <div class="bg__object" ref="dimensions"></div>
      <div class="bg__mask">
        <div class="fill-left jsLeft" ref="jsLeft"></div>
        <div class="fill-right jsRight" ref="jsRight"></div>
        <div class="fill-bottom jsBottom" ref="jsBottom"></div>
        <div class="fill-top jsTop" ref="jsTop"></div>
      </div>
      <div class="bg__icon-text">
        <img class="scrollObj" ref="xxx" src="@/assets/images/icon-text.png" alt="" />
      </div>
    </div>
    <div class="about__summary__container" ref="showheader">
      <div class="about-summary__title scrollObj">
        <p v-if="$bowser.mobile">
          Shanghai Face Decoration Design<br />Engineering Co., Ltd was founded<br />in 2006.
        </p>
        <p v-else>Shanghai Face<br />Decoration Design<br />Engineering Co., Ltd</p>
      </div>
      <div class="about-summary__main-body">
        <div class="body__item">
          <p class="scrollObj">
            上海飞视装饰设计工程有限公司创立于2006年。坐标国际化都市上海，于成都设立分公司，飞视设计在成立16周年中，深耕于室内设计行业，不断向产业链上下游延伸，积极拓展业务领域，服务范围覆盖中国各⼤省市。擅长现代、新中式风格创意展示设计，业务涵盖地产、商业、酒店、办公、高端私宅、文旅等设计；助⼒合作商、开发商实现创新落地与精装配套研发。我们的团队年轻激情，汇集了来自全国各地的创意人才，多元的背景为公司带来丰富的想象力与无穷的创造力，致力于为每个项目添加前所未有的元素，创造新的视觉感官，开辟新的设计语言。凭借各类专业技术特长，公司在业内权威设计大奖中屡获殊荣，获得包括德国
            iF product design award Design Excellence奖、美国 Architecture MasterPrize
            建筑大师奖、德国红点传达设计大奖、英国 International Property Awards
            国际房地产奖、并广受著名媒体的青睐，如《中国室内设计年鉴》《设计家》《建筑与文化》等。以国际化的标准不断精进，矢志成为具有世界影响力的室内平台。
          </p>
          <p class="scrollObj">
            飞视设计秉持良好的服务意识，先进的研发意识，及丰富的项目管理经验、规范化设计管控流程，在全国与多家房地产合作并圆满完成设计项目，与此同时，飞视设计将卓越的专业技能、严谨的工作态度和对精确工艺的追求融入到每个细节中，做到“入乎其内，出乎其外”，实现开发理念与设计构思独一无二的完美融合。
          </p>
        </div>
        <div class="body__item">
          <p class="scrollObj">
            Shanghai Face Decoration Design Engineering Co., Ltd. was founded in 2006. Coordinates
            the international city of Shanghai, set up a branch in Chengdu.
          </p>
          <p class="scrollObj">
            In the 16th anniversary of it's establishment, Face Design has been deeply engaged in
            the interior design industry, continuously extending to the upstream and downstream of
            the industrial chain, and actively expanding business fields. It‘s service scope covers
            all major provinces and cities in China. It is good at modern and new Chinese style
            creative display design, and it‘s business covers real
            estate、commercial、hotel、office、high-end private residence、cultural travel and other
            design. Awards include iF Product Design Award, Architecture MasterPrize, Reddot award,
            and widely favored by famous media, such as China Interior Design Annual,
            Designer&Designing，Architecture&Culture, etc. With the international standards of
            continuous improvement, determined to become a world influential indoor platform.
          </p>
          <p class="scrollObj">
            With a good sense of service, advanced sense of research and development, rich
            experience in project management and standardized design control process, Face Design
            has cooperated with many real estate companies across the country and successfully
            completed design projects. At the same time, Face Design incorporates excellent
            professional skills, rigorous work attitude and the pursuit of accurate technology into
            every detail, to achieve "Dig deep into it's essence，and observe it beyond it's
            boundaries”，and the perfect integration of development concept and design concept
            unique.
          </p>
        </div>
      </div>
    </div>
    <div class="about__wtopia-container changes" ref="change">
      <div class="animation__line scrollObj"></div>
      <div class="wtopia__inner">
        <div class="wtopia__title scrollObj">
          <!-- <p>wtopia</p> -->
          <i class="logo"></i>
        </div>
        <div class="wtopia__main-content">
          <p class="cn scrollObj">
            屋托邦住宅研究中心，于2021年成立，隶属于上海飞视装饰设计工程有限公司。团队聘请了资深高级研发顾问，同时由一帮有深度思考能力、热爱生活、创意无限的年轻人组成。自成立以来，屋托邦秉承像螺丝钉一样不断钻研的精神，致力于产品研发、战略研究、商业策划等服务，与多家房地产领军企业展开深度合作共同提升产品力。未来期待得到外界对屋托邦产品的反馈和认可，因此，在创新研究的道路上我们永不停歇……
          </p>
          <p class="en scrollObj">
            Founded in 2021, the Wtopia Residential Research Center is affiliated to Shanghai Face
            Decoration Design Engineering Co., Ltd. There are several senior R&D consultants in our
            team as well as a group of young people,who are filled with ability to think deeply, a
            passion for life and unlimited creativity. Since its starting, Wtopia, in the spirit of
            constant research as a screw, has been dedicated to product R&D, strategic study,
            business planning, etc. ,while cooperated with many real estate leaders to jointly
            improve productivity. Wtopia products, however, are expected to receive feedback and
            recognition from the outside in the near future. And our team will keep innovating.
          </p>
        </div>
        <!-- <div class="wtopia__logo scrollObj"></div> -->
      </div>
    </div>
    <div class="about__partner__container" id="member" ref="member">
      <div class="partner__inner">
        <div class="animation__line scrollObj"></div>
        <div class="partner__title scrollObj">partner</div>
        <div class="partner-items__container">
          <div class="items__inner" v-if="members.length > 0">
            <div
              class="partner__item scrollObj"
              v-for="member in members"
              :key="member.id"
              @click="onMember(member.id)"
              tag="div"
            >
              <div class="item__inner">
                <div class="partner__item-body">
                  <div class="partner__cover">
                    <img :src="member.src" alt="" />
                  </div>
                  <div class="partner__info">
                    <p class="info__name">
                      <span>{{ member.name.cn }}</span
                      ><span>{{ member.name.en }}</span>
                    </p>
                    <p class="info__other">
                      <span>{{ member.id == 1 ? member.title.cn : member.job.cn }}</span> |
                      <span>{{ member.id == 1 ? member.job.cn : member.title.cn }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about__cooperation__container changes" ref="change">
      <div class="cooperation__inner">
        <div class="animation__line scrollObj"></div>
        <div class="cooperation__title scrollObj">cooperation</div>
        <div class="cooperation__items__container">
          <p class="fk scrollObj">排名顺序不分先后</p>
          <div
            class="cooperation__row"
            :class="{
              scrollObj: $bowser.mobile
            }"
            v-for="(row, index) in formatPartners"
            :key="index"
          >
            <div class="cooperation__item scrollObj" v-for="(item, i) in row" :key="i">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import GSAP from 'gsap';
import { mapState } from 'vuex';
import events from '@/utils/eventBus';

export default {
  name: 'view-about',
  data() {
    return {
      partners: [
        // b
        {
          src: require('@/assets/images/partners/4.jpg'),
        },
        {
          src: require('@/assets/images/partners/21.jpg'),
        },
        // d
        {
          src: require('@/assets/images/partners/50.jpg'),
        },
        {
          src: require('@/assets/images/partners/53.jpg'),
        },
        {
          src: require('@/assets/images/partners/28.jpg'),
        },
        {
          src: require('@/assets/images/partners/29.jpg'),
        },
        // h
        {
          src: require('@/assets/images/partners/44.jpg'),
        },
        {
          src: require('@/assets/images/partners/45.jpg'),
        },
        {
          src: require('@/assets/images/partners/2.jpg'),
        },
        {
          src: require('@/assets/images/partners/19.jpg'),
        },
        {
          src: require('@/assets/images/partners/22.jpg'),
        },
        {
          src: require('@/assets/images/partners/23.jpg'),
        },
        {
          src: require('@/assets/images/partners/26.jpg'),
        },
        {
          src: require('@/assets/images/partners/27.jpg'),
        },
        {
          src: require('@/assets/images/partners/33.jpg'),
        },
        {
          src: require('@/assets/images/partners/37.jpg'),
        },
        // j
        {
          src: require('@/assets/images/partners/55.jpg'),
        },
        {
          src: require('@/assets/images/partners/12.jpg'),
        },
        {
          src: require('@/assets/images/partners/20.jpg'),
        },
        {
          src: require('@/assets/images/partners/14.jpg'),
        },
        {
          src: require('@/assets/images/partners/32.jpg'),
        },
        // k
        {
          src: require('@/assets/images/partners/40.jpg'),
        },
        // l
        {
          src: require('@/assets/images/partners/38.jpg'),
        },
        {
          src: require('@/assets/images/partners/7.jpg'),
        },
        {
          src: require('@/assets/images/partners/17.jpg'),
        },
        {
          src: require('@/assets/images/partners/8.jpg'),
        },
        {
          src: require('@/assets/images/partners/25.jpg'),
        },
        {
          src: require('@/assets/images/partners/46.jpg'),
        },
        {
          src: require('@/assets/images/partners/54.jpg'),
        },
        // r
        {
          src: require('@/assets/images/partners/6.jpg'),
        },
        {
          src: require('@/assets/images/partners/15.jpg'),
        },
        // s
        {
          src: require('@/assets/images/partners/5.jpg'),
        },
        {
          src: require('@/assets/images/partners/24.jpg'),
        },
        {
          src: require('@/assets/images/partners/30.jpg'),
        },
        {
          src: require('@/assets/images/partners/36.jpg'),
        },
        {
          src: require('@/assets/images/partners/41.jpg'),
        },
        // v
        {
          src: require('@/assets/images/partners/1.jpg'),
        },

        // x
        {
          src: require('@/assets/images/partners/13.jpg'),
        },
        {
          src: require('@/assets/images/partners/9.jpg'),
        },
        {
          src: require('@/assets/images/partners/31.jpg'),
        },
        {
          src: require('@/assets/images/partners/34.jpg'),
        },
        {
          src: require('@/assets/images/partners/35.jpg'),
        },
        // w
        {
          src: require('@/assets/images/partners/48.jpg'),
        },
        // y
        {
          src: require('@/assets/images/partners/56.jpg'),
        },
        {
          src: require('@/assets/images/partners/52.jpg'),
        },
        {
          src: require('@/assets/images/partners/43.jpg'),
        },
        {
          src: require('@/assets/images/partners/42.jpg'),
        },
        {
          src: require('@/assets/images/partners/11.jpg'),
        },
        {
          src: require('@/assets/images/partners/16.jpg'),
        },
        // z
        {
          src: require('@/assets/images/partners/3.jpg'),
        },
        {
          src: require('@/assets/images/partners/10.jpg'),
        },
        {
          src: require('@/assets/images/partners/18.jpg'),
        },
        {
          src: require('@/assets/images/partners/39.jpg'),
        },
        {
          src: require('@/assets/images/partners/47.jpg'),
        },
        {
          src: require('@/assets/images/partners/49.jpg'),
        },
        {
          src: require('@/assets/images/partners/51.jpg'),
        },
      ],
      getScrollTop: 0,
    };
  },
  computed: {
    ...mapState(['members']),
    formatPartners() {
      const arr = [];
      const data = this.partners;
      const offset = this.$bowser.mobile ? 4 : 8;
      for (let i = 0; i < data.length; i += offset) {
        arr.push(data.slice(i, i + offset));
      }
      return arr;
    },
    hash() {
      return this.$route.hash;
    },
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      events.$emit('pageMounted');
      if (this.$route.hash) {
        console.log('111');
        setTimeout(() => {
          document.querySelector(this.$route.hash).scrollIntoView();
        }, 500);
      }
    });
  },
  destroyed() {
    document.body.classList.remove('normal', 'white');
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    init() {
      const bodyWidth = document.body.clientWidth;
      const screenHeight = window.innerHeight;
      document.body.classList.add('normal', 'white');
      this.$nextTick(() => {
        const $dimensions = this.$refs.dimensions.getBoundingClientRect();
        this.$refs.jsLeft.style.width = `${(bodyWidth - $dimensions.width) / 2}px`;
        this.$refs.jsRight.style.width = `${(bodyWidth - $dimensions.width) / 2}px`;
        this.$refs.jsTop.style.height = `${(screenHeight - $dimensions.height) / 2}px`;
        this.$refs.jsBottom.style.height = `${(screenHeight - $dimensions.height) / 2}px`;
        setTimeout(() => {
          this.$refs.xxx.classList.add('animated');
        }, 500);

        console.log(this.hash);
        if (this.hash === '#member') {
          // document.documentElement.scrollTop = document.getElementById(this.hash.substring(1)).offsetTop - 100;
          this.$refs.member.scrollIntoView(true);
        }
      });

      window.addEventListener('scroll', this.onScroll);
    },
    onScroll() {
      // const { scrollTop } = document.scrollingElement;
      // let rate = scrollTop / (window.innerHeight * 1.01);
      // if (rate > 1) {
      //   rate = 1;
      // }
      // const s = 1 - rate;
      // GSAP.set(this.$refs.jsLeft, {
      //   scaleX: s,
      // });
      // GSAP.set(this.$refs.jsRight, {
      //   scaleX: s,
      // });
      // GSAP.set(this.$refs.jsTop, {
      //   scaleY: s,
      // });
      // GSAP.set(this.$refs.jsBottom, {
      //   scaleY: s,
      // });
      // GSAP.set(this.$refs.bg, {
      //   scale: 0.5 * rate + 0.5,
      // });
      const changes = Array.from(document.querySelectorAll('.changes'));
      const c1 = changes['0'].getBoundingClientRect();
      const c2 = changes['1'].getBoundingClientRect();
      if (c2.top <= window.innerHeight || c1.top > window.innerHeight) {
        document.body.classList.add('white');
      } else {
        document.body.classList.remove('white');
      }
      // const current = changes.find((item) => {
      //   const bcr = item.getBoundingClientRect();
      //   return bcr.top <= window.innerHeight && bcr.bottom > 0;
      // });
      // if (current) {
      //   const { theme } = current.dataset;
      //   document.body.classList.add(theme === 'gray' ? 'normal' : 'white');
      // }
      // console.log(current);
      // const changeObj = this.$refs.change.getBoundingClientRect();
      // if (changeObj.top <= window.innerHeight && changeObj.bottom > 0) {
      //   document.body.classList.add('white');
      // } else {
      //   document.body.classList.remove('white');
      // }

      const showHeaderObj = this.$refs.showheader.getBoundingClientRect();
      if (showHeaderObj.top <= 0) {
        document.querySelector('.header__container').classList.remove('hidden');
      } else {
        document.querySelector('.header__container').classList.add('hidden');
      }
    },
    onMember(id) {
      this.$router.push({
        path: `/member/${id}`,
      });
    },
  },
};
</script>

<style></style>
